/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

		// function to make the header sticky on page scroll
		function sticky_header($_opt_relative_header){
			var sticky_nav = $('.navbar-fixed-top');
			var sticky_start = 100;
			if($(document).scrollTop() > sticky_start){
				sticky_nav.addClass("sticky-header");
			}else {
				sticky_nav.removeClass("sticky-header");
			}
		}

		sticky_header();
		$(window).scroll(function(){
			 sticky_header();
		});



		// hero content load animation
		var hero_image = new Image();
			hero_image.src = $('.hero-image').attr('data-src');

			$(hero_image).load( function () {
				$('.hero-image').show(function(){
					$('body').addClass('loaded');

				});
			});


		// hero slider

		$(".hero-container").owlCarousel({
				singleItem: true,
				navigation : false,
				pagination : true,
				autoPlay : true,
				afterAction: function(el){
				   this.$owlItems.removeClass('active');
				   //add class active
				   this.$owlItems.eq(this.currentItem).addClass('active');

				}
		});


		// Scrolling nav
		$('.menu-item > a, a.scroll-to, .scroll-to a').click(function(e){

			var tar = this.hash,
				tarOff = $(tar).offset(),
				winWidth = $(window).width(),
				winOff = $('.navbar').outerHeight()-1;

			if( typeof tarOff !== 'undefined') {
				e.preventDefault();
				e.stopPropagation();

				if($(this).parent('.menu-item').length > 0) {
					if($(".main-navigation").hasClass('mobile-nav')) {
						$(".menu-toggle").toggleClass("close-menu");
						$(".main-navigation").toggleClass("mobile-nav");
					}
				}

				$("html, body").animate( {
					scrollTop:tarOff.top - winOff
				}, 'slow');

				// $("html, body").animate({scrollTop:tarOff.top - winOff - 40}, 1200);
			}

		});



		// Sok VC Post Element
		$(document).ready(function(){

			$(".post-list a").click(function(e) {
				e.preventDefault();
			});

			$(".overlay-close").click(function(e) {
				e.preventDefault();
				$('.overlay-container').hide();
			});

			$(".post-list").click(function(e) {

				$(".post-list").removeClass('active');
				$(this).addClass('active');
				var objPostItem = $(this);
				$('.overlay-container').fadeIn(100, function(){

					var overlay_title = objPostItem.find('.post-title a').attr('title');
					var overlay_content = objPostItem.find('.post-content').html();

					$('.overlay-title-heading').html(overlay_title);
					$('.overlay-content').html(overlay_content);

				});

			});
		});




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

			// flag tooltip
			$(document).ready(function(){
				$('[data-toggle="tooltip"]').tooltip();
				$('[data-toggle=popover]').on('shown.bs.popover', function () {
					$('.popover').css('top',parseInt($('.popover').css('top')) + 102 + 'px');
				});
			});


		// Bx Slider for Discography and Music Player


		$(document).ready(function(){

			$('.discography').bxSlider({
				mode: 'vertical',
				pager: false,
				autoStart: false,
				minSlides: 11,
				moveSlides: 1,
				slideMargin: 5,
				infiniteLoop: false,
				hideControlOnEnd: true,
				touchEnabled: false,
			});

			// Load default album and on click event
			$('.album-link').on('click', function(e) {
				e.preventDefault();
				$('.album-link').removeClass('active');
				load_album($(this).index('.album-link'));
			});

			function load_album(index) {
				$('.album-link').eq(index).addClass('active');
				var album_title = $('.album-link').eq(index).attr('data-title');
				var album_year = $('.album-link').eq(index).attr('data-year');
				var album_summary = $('.album-link').eq(index).attr('data-summary');
				var album_featuredimg = $('.album-link').eq(index).attr('data-featuredimg');
				var album_permalink = $('.album-link').eq(index).attr('data-permalink');

				$('.media-album .album-title').html(album_title);
				$('.media-album .album-year').html(album_year);
				$('.media-album .album-summary').html(album_summary);
				$('.media-album .album-art img').attr('src', album_featuredimg);
				$('.media-album .call-to-action a').attr('href', album_permalink);


				var music_data = JSON.parse($('.album-link').eq(index).data('preview'));

				//console.log(eval(music_data));
				var myPlaylist = new jPlayerPlaylist({
					jPlayer: "#jquery_jplayer_1",
					cssSelectorAncestor: "#jp_container_1"
				}, {
				swfPath: "../dist/jplayer",
				supplied: "mp3",
				wmode: "window",
				useStateClassSkin: true,
				autoBlur: false,
				smoothPlayBar: true,
				keyEnabled: true
				});
				myPlaylist.setPlaylist(eval(music_data));
			}

			load_album(0);

		});


		// team slider
		$(document).ready(function(){
			$(".team-container").owlCarousel({
				singleItem: true,
				navigation : false,
				pagination : true,
				autoPlay : true,
				afterAction: function(el){
				   this.$owlItems.removeClass('active');
				   //add class active
				   this.$owlItems.eq(this.currentItem).addClass('active');

				}
			});
		});







      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
	// Study  page
    'study': {
      init: function() {
        // JavaScript to be fired on the study page

        var $study = $('#study-content'),
            $studyContent = $study.find('#study-content-text');

        var adjustPadding = function() {
          if ($studyContent.length !== 1 || $study.length !== 1) {
            return false;
          }

          var left = $study.css('left'),
              paddingLeft = Math.abs(parseFloat(left));

          $studyContent.css('paddingLeft', paddingLeft + 'px');
        }

        $(document).ready(function() {
          if ($study.length == 1) {
            adjustPadding();

            $(window).on('resize', adjustPadding);
          }
        })
      }
    },
    // Events page
    'events': {
      init: function() {
        // JavaScript to be fired on the events page

		  fbq('track', 'ViewContent', {
		  });

		  $(document).ready(function(){


			$('.event .entry').on('click', function(e) {
				e.preventDefault();
				$('.event .entry').removeClass('active');
				$('.event-details').hide();
				$(this).addClass('active').next('.event-details').show();
				$(this).focus();

				$("html, body").animate( {
					scrollTop: $(this).offset().top - 200
				}, 'slow');


			});
			$(".entry a").click(function(e) {
				//alert("thank you!");
				//prevent parent click event
				e.stopPropagation();
			})
		});

      }
    },

    'videos': {
      init: function() {
        var $videoContainer = $('#videos-gallery-container'),
        $popupContainer = $(
          '<div id="video-gallery-popup-container" class="hidden">\
            <div class="video-gallery-popup-content">\
              <div id="video-gallery-player"></div>\
            </div>\
           </div>'
        ),
        $body = $(document.body);

        var videoId, player, tag;
        function onYouTubeIframeAPIReady() {
          player = new YT.Player('video-gallery-player', {
            height: '390',
            width: '640',
            videoId: videoId,
            events: {
              'onReady': onPlayerReady,
              'onStateChange': onPlayerStateChange
            }
          });
        }

        function onPlayerReady(e) {
          e.target.playVideo();
        }

        function onPlayerStateChange(e) {
          var state = e.data;

          // unstarted state
          if (state == -1) {
            $body.trigger('youtube-video-load')
          }
        }

        // add the popup container.
        $('body').append($popupContainer);

        function extractIdFromLink(link) {
          if (link.search('v=') != -1) {
            var segments = link.split('v=');

            return segments[segments.length - 1];
          } else {
            var segments = link.split('/');
            return segments[segments.length - 1];
          }
        }

        function attachThumbnail(el, id) {
          var src = "https://img.youtube.com/vi/" +id+ "/hqdefault.jpg",
              img = '<div class="video-gallery-img-wrapper"><img class="video-gallery-img" src="' +src+ '"></div>',
              overlay = '<span class="video-gallery-item-overlay"></span>';
              control = '<span class="video-play-btn"></span>';

          $(el).append(img).append(overlay).append(control);
        }

        function loadYT(id) {
          if (!tag) {
            tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            videoId = id;
          } else {
              // load video by id
              player.loadVideoById(id.trim());
          }
        }

        function openVideo(el) {
          var $el = $(el),
              id = extractIdFromLink($el.attr('data-link'));

          loadYT(id);

          $body.one('youtube-video-load', function(e) {
            $popupContainer.removeClass('hidden');
          })
        }

        function closeVideo() {
          player && player.stopVideo();
          $popupContainer.addClass('hidden')
        }

        $videoContainer.find('.video-gallery-item').each(function(i, el) {
          var $el = $(el), link = $el.attr('data-link');

          if (link.length !== 0) {
            var id = extractIdFromLink(link);

            0 < id.length && attachThumbnail(el, id);
          }
        });

        $videoContainer.on('click', '.video-gallery-item', function(e) {
          openVideo(this)
        });

        $popupContainer.on('click', closeVideo);

        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
